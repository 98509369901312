$font-default: 'Nunito', sans-serif;

body {
    font-family: $font-default;
    font-size: 16px;
}

.heading {
    color: $primary;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 16px;
}