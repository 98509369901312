body,
html {
  height: auto;
  min-height: 100vh;
  // overflow-x: hidden;
  position: relative;
}

* {
  box-sizing: border-box;
  font-size: 16px;
}

body {
    margin: 0;
    font-family: $font-default, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 0 0 16px 0;
}

img {
  display: block;
  max-width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 16px 0;
}

input, textarea {
  border: 1px solid $primary;
  border-radius: 4px;
  font-family: $font-default;
  height: 50px;
  outline: none;
  padding: 8px 12px;
  transition: 0.3s ease;
  width: 100%;

  &:active,
  &:focus {
    border: 2px solid $primary;
  }
}

textarea {
  min-height: 130px;
  resize: none;
}

.error-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  text-align: center;
  width: 100vw;

  &__symbol {
    border: 3px solid $dangerRed;
    border-radius: 50%;
    color: $dangerRed;
    font-size: 32px;
    font-weight: bold;
    margin: 0;
    padding: 8px 22px;
  }

  &__title {
    color: $dangerRed;
    font-size: 20px;
    font-weight: bold;
    margin: 8px 0 16px;
  }

  &__message {
    text-align: center;
  }
}