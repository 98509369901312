.powered-by {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 32px;

  img {
    display: inline-block;
    margin-left: 8px;
    max-width: 140px;
    width: auto;
  }
}
.contribution-page {
  background: white;

  .no-data {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    height: 100vh;
    justify-content: center;
    text-align: center;

    .heading {
      font-size: 20px;
    }

    &__logo {
      margin-bottom: 8px;
      max-width: 134px;
    }
  }
}

.contribution {
  &__image {
    align-items: center;
    background: $primary;
    display: flex;
    justify-content: center;
    height: 46vh;
  }

  &__box {
    background-color: white;
    border-radius: 30px 30px 0 0;
    box-shadow: 0px -3px 12px rgba(0, 0, 0, 0.3);
    height: 100%;
    margin: -60px auto;
    max-width: 500px;
    min-height: calc(54vh + 60px);
    padding: 32px 16px;
    position: relative;
    z-index: 1;
  }

  &__title {
    color: $primary;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 8px;
    text-align: center;
  }

  &__total {
    margin-bottom: 16px;
    text-align: center;

    p {
      margin: 0;

      &:first-of-type {
        color: $primary;
        font-size: 48px;
        font-weight: bold;
      }

      &:last-of-type {
        font-size: 16px;

        span {
          font-weight: bold;
        }
      }
    }
  }

  &__description {
    padding: 16px 0;
    p {
      &:first-of-type {
        color: $primary;
        font-weight: bold;
        margin-bottom: 6px;
      }

      &:last-of-type {
        color: #757575;
        margin: 0;
        // margin-top: -12px;
      }
    }
  }

  &__location {
    align-items: center;
    display: flex;
    justify-content: flex-start;

    span {
      background: linear-gradient(to right, $primary, $secondary);
      border-radius: 6px;
      color: white;
      display: inline-block;
      font-size: 14px;
      font-weight: bold;
      margin-right: 8px;
      padding: 8px 4px;
    }

    p {
      color: #757575;
      margin: 0;
    }
  }

  &__time-box {
    border: 2px solid $primary;
    border-radius: 10px;
    padding: 12px 16px;

    svg {
      fill: $primary;
      height: 100%;
      width: 30px;
    }

    p {
      margin: 0;
    }

    .time-ending {
      align-items: center;
      color: $primary;
      display: flex;
      font-size: 20px;
      font-weight: bold;
      justify-content: center;

      p {
        margin-left: 8px;
      }
    }

    .time-row {
      align-items: center;
      display: flex;
      justify-content: space-between;

      span {
        font-weight: bold;

        &:first-of-type {
          align-items: center;
          color: $primary;
          display: flex;

          span {
            margin-left: 8px;
          }
        }

        &.days {
          font-size: 20px;
        }
      }
    }

    .time-ended {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    }
  }

  .checkout-form {
    .heading {
      margin: 16px 0 0;
      text-align: center;
    }
  }

  .donation-btns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 8px 0 16px;
  }

  .donationBtn {
    align-items: center;
    border: 3px solid #d0d5dd;
    border-radius: 8px;
    color: $primary;
    display: inline-flex;
    font-size: 32px;
    font-weight: bold;
    justify-content: center;
    margin-top: 8px;
    padding: 20px 30px;
    transition: 0.2s ease;
    width: calc(50% - 5px);

    &:hover {
      border-color: lighten($primary, 15%);
      cursor: pointer;
    }

    &--active {
      border-color: $primary;

      &:hover {
        border-color: $primary;
      }
    }

    &--custom {
      font-size: 24px;
    }
  }

  .donation-custom {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
  }

  .cancel-custom {
    align-items: center;
    background-color: white;
    border: 2px solid $primary;
    border-radius: 50%;
    color: $primary;
    display: inline-flex;
    font-weight: bold;
    justify-content: center;
    margin-left: 16px;
    padding: 4px 8px;
    transition: 0.3s ease;
    user-select: none;

    &:hover {
      background-color: $primary;
      color: white;
      cursor: pointer;
    }
  }

  .donation-inputs {
    input {
      margin-bottom: 12px;
    }

    textarea {
      margin-bottom: 16px;
    }
  }

  .donation-fee {
    padding-bottom: 32px;

    h2 {
      color: $primary;
    }

    .fee-text {
      color: #757575;
    }

    label {
      align-items: center;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      padding: 0 16px;

      .checkContainer {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-right: 8px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      &:hover {
        input {
          ~ .checkmark {
            background-color: #ccc;
          }
        }
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked {
          ~ .checkmark {
            background-color: $primary;

            &:after {
              display: block;
            }
          }
        }
      }

      .checkmark {
        position: absolute;
        top: -12px;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
        transition: 0.2s ease;

        &:after {
          display: none;
          left: 9px;
          top: 5px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          content: "";
          position: absolute;
        }
      }
    }
  }

  .donation-fee-section {
    .fee {
      align-items: center;
      color: #757575;
      display: flex;
      justify-content: space-between;
      padding: 0 8px 4px;

      p {
        display: inline-block;
        margin: 0;
      }
    }
  }

  .stripe-element {
    margin: 16px 0;
  }

  .stripe-errors {
    color: $dangerRed;
    font-weight: bold;
    margin: 16px 0;
    text-align: center;
  }
}

.payment-module {
  align-items: center;
  background: rgba(0, 0, 0, 0.75);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;

  &__buttons {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  form {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
    margin: 16px;
    padding: 16px;
  }
}

.success-section {
  background-color: white;
  bottom: 0;
  font-weight: normal;
  left: 0;
  padding: 20px 0;
  position: absolute;
  text-align: center;
  top: 30px;
  width: 100%;

  &__logo {
    margin: 0 auto 8px;
    max-width: 134px;
    opacity: 0.2;
  }

  &__title {
    font-size: 16px;
    font-weight: bold;

    span {
      color: $primary;
      display: block;
      font-size: 24px;
      margin-top: 4px;
    }
  }

  &__blurb {
    margin: 32px 0 16px;
  }

  &__buttons {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .donate-again {
    color: $primary;
    cursor: pointer;
    display: inline-block;
    margin-top: 16px;
    text-decoration: underline;
    transition: 0.3s ease;

    &:hover {
      color: lighten($primary, 10%);
    }
  }
}

@media only screen and (min-width: 1200px) {
  .contribution-page {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
  }

  .contribution {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin: 0 auto;
    max-width: 1200px;

    &__image {
      border-radius: 5px;
      height: calc(1200px * 0.55);
      width: calc(1200px * 0.55);
    }

    &__box {
      border-radius: 10px;
      box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.21);
      margin: 0;
      margin-left: -10px;
      max-height: 80vh;
      min-width: 40%;
      overflow: scroll;
    }
  }

  .payment-module {
    background: white;
    // position: absolute;

    form {
      box-shadow: none;
      min-width: 40vw;
    }
  }

  .success-section {
    align-items: center;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 16px;
    top: 0;
  }
}
