.unknown {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    height: 100vh;
    justify-content: center;
    text-align: center;

    &__logo {
        margin-bottom: 8px;
        max-width: 134px;

    }
}