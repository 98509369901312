.button {
    align-items: center;
    background: linear-gradient(to right, $primary, $secondary,);
    border: none;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    font-weight: bold;
    display: inline-flex;
    height: 50px;
    justify-content: center;
    outline: none;
    padding: 8px 16px;
    position: relative;
    transition: 0.3s ease;

    &:hover {
        background: linear-gradient(to right, lighten($primary, 10%), lighten($secondary, 10%),);
    }

    &--lg {
        width: 100%;
    }

    &--wide {
        width: 250px;
    }

    &--disabled,
    &:disabled {
        background: #999;
        cursor: not-allowed;
        pointer-events: none;
        user-select: none;
    }

    &--loading {
        cursor: not-allowed;
        font-size: 0;
        pointer-events: none;
        
        &:after {
            animation: spin 2s linear infinite;
            border: 3px solid transparent;
            border-top: 3px solid white;
            border-radius: 50%;
            content: '';
            height: 30px;
            left: 0;
            margin: 0 auto;
            opacity: 1;
            position: absolute;
            right: 0;
            top: 8px;
            transition: all 0.3s ease-in, visibility 0s linear 0.35s;
            visibility: visible;
            width: 30px;
          }
    }
}

.cancel-button {
    color: $dangerRed;
    cursor: pointer;
    display: inline-block;
    margin-top: 16px;
    text-decoration: underline;
    transition: 0.3s ease;

    &:hover {
        color: darken($dangerRed, 10%);
    }
}