.loading-section {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    text-align: center;
    width: 100vw;

    &__text {
        font-size: 18px;
        font-weight: bold;
        margin: 0;
        margin-left: 12px;
    }
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 48px;
    height: 48px;
    // margin: 8px;
    border: 4px solid $primary;
    border-radius: 50%;
    animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $primary transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }