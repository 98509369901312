.dropdown {
    overflow: hidden;
    &__title {
        background-color: white;
        border-bottom: 1px solid white;
        border-top: 1px solid white;
        color: $primary;
        cursor: pointer;
        font-weight: bold;
        padding: 16px 8px;
        padding-right: 30px;
        position: relative;
        user-select: none;

        .close {
            border-style: solid;
            border-width: 2px 2px 0 0;
            content: '';
            display: inline-block;
            height: 12px;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-45%) rotate(135deg);
            transition: 0.45s ease-in;
            vertical-align: top;
            width: 12px;
        }
    }

    &__content {
        overflow: hidden;
        padding: 0 16px;
        transition: 0.45s ease-out;
    }

    &--active {
        .dropdown {
            &__title {
                border-bottom: 1px solid #eee;
                border-top: 1px solid #eee;
                
                .close {
                    transform: translateY(-45%) rotate(-45deg);
                }
            }
            &__content {
                padding: 16px;
            }
        }
    }
}